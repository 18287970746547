import { LinkStyle } from '@dreamworld/material-styles/link.js';

import { css, unsafeCSS } from 'lit';

export const shimmerStyle = css`
  background: #777;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
`;

export const sharedStyles = css`
  ${unsafeCSS(LinkStyle)}

  :host {
    display: block;
  }

  :host,
  :host * {
    box-sizing: border-box;
  }

  /* disabled select browser text */
  :host,
  :host > * {
    -webkit-user-select: auto;
    -khtml-user-select: auto;
    -moz-user-select: auto;
    -o-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
  }

  /* enabled select browser text */
  :host[user-select-text],
  :host > *[user-select-text] {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: -moz-text;
    -o-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }

  /* hide hidden el on hidden attribute */
  :host([hidden]),
  [hidden],
  *[hidden] {
    display: none !important;
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .uppercase {
    text-transform: uppercase !important;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: var(--mdc-theme-primary);
    outline: none;
  }

  .bold,
  strong {
    font-weight: 500;
  }

  hr {
    border-color: var(--divider-color);
    border-width: 1px 0 0 0;
    margin: 8px 0px;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .shimmer {
    ${unsafeCSS(shimmerStyle)};
  }

  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }

  // START: Scrollbar styles
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.32) transparent;
  }

  ::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.32);
    background-clip: content-box;
    border: 4px solid transparent;
    border-radius: calc(12px / 2);
  }
  // END: Scrollbar styles
`;

export default sharedStyles;
