import { filter, find, isEmpty, isEqual, isMatchWith, orderBy, pick, pickBy, slice, sortBy, split, uniq, uniqBy, forEach } from 'lodash-es';
import memoize from 'proxy-memoize';
import { doc, doc as dreamdbDoc, queryResult } from '../dreamdb/selectors.js';
import * as playerSelectors from '../players/selectors.js';
import * as scheduleSelectors from '../schedules/selectors';
import * as videoSelectors from '../videos/selectors';
import * as router from '../router/selectors.js';
import * as appSelectors from '../app/selectors.js';
import { LIST_PAGE_NAME } from './constants';
import moment from 'moment/dist/moment.js';
import { createSelector } from 'reselect';

/**
 * @returns {Array} list of kendras.
 */
export const allKendras = (state) => queryResult(state, 'all-kendras');

const schedulableKendras = createSelector(allKendras, (kendras) => {
  return filter(kendras, (kendra) => kendra.status === 'ACTIVE' && kendra.player);
});

/**
 * @returns {Array} list of Kendras matching applied `filters()`.
 *  Sorted alphabetically on region.
 */
export const list = memoize((state) => {
  let _kendras = allKendras(state);
  if (!_kendras) {
    return;
  }

  let _filters = filters(state);
  _filters = { ..._filters };

  convertToString(_filters, 'primaryMobileNo');
  convertToString(_filters, 'code');
  setPlayer(_filters, state);
  const region = _filters.region;
  delete _filters.region;

  _kendras = _kendras.filter((kendra) => kendraMatchesFilters(kendra, _filters, region));
  _kendras = orderBy(_kendras, (k) => [k.country, k.state, k.sanghat, k.district, k.taluka, k.village]);
  return _kendras;
});

/**
 * Mutates `filters`. Sets `filters.player` based on `playerCode`.
 */
const setPlayer = (filters, state) => {
  if (!filters.playerCode) {
    return;
  }

  filters.player = playerSelectors.findByCode({ state, playerCode: filters.playerCode })?._id;
  delete filters.playerCode;
}

const convertToString = (obj, key) => {
  if (obj[key] === undefined) {
    return;
  }
  obj[key] = '' + obj[key];
}

const kendraMatchesFilters = (kendra, filters, region) => {
  if (!filters) {
    return true;
  }

  let matched = isMatchWith(kendra, filters);
  if (region && matched) {
    matched = kendraMatchesQuery(kendraRegion(kendra), region);
  }
  return matched;
}

/**
 * Current applied filters on kendras.
 * @param {Object} state Redux state. 
 * @returns {Object} Applied filters. e.g. `{ status: "", region: "", weekDay: "", code: "", primaryMobileNo: "", playerCode: "" }`. 
 *  `undefined` when no filter is applied.
 */
export const filters = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return;
  }

  const pageParams = router.pageParams(state);
  let filters = pick(pageParams, ['region', 'status', 'weekDay', 'primaryMobileNo', 'code', 'playerCode']);
  filters = pickBy(filters, (val) => val !== '');
  return isEmpty(filters) ? undefined : filters;
};

/**
 * It returns true if filter dialog is opened. false otherwise.
 * @param {Object} state
 */
export const filterDialogOpened = (state) => {
  const pageName = router.pageName(state);
  if (pageName !== LIST_PAGE_NAME) {
    return false;
  }

  const pageParams = router.pageParams(state);
  return pageParams?.action === 'filter';
};

/**
 * @returns {String[]} Selected kendraIds on the list page. When no selection, empty array. 
 */
export const selection = (state) => state.kendras?.list?.selection;

export const kendra = (state, kendraId) => dreamdbDoc(state, kendraId);

function regionPerms(kendra) {
  const regions = [];
  const { country, state, sanghat, district, taluka, village, code } = kendra;
  regions.push({ type: 'country', country, value: country });
  regions.push({ type: 'state', country, state, value: `${country}/${state}` });
  regions.push({ type: 'sanghat', country, state, sanghat, value: `${country}/${state}/${sanghat}` });
  regions.push({ type: 'district', country, state, sanghat, district, value: `${country}/${state}/${sanghat}/${district}` });
  regions.push({
    type: 'taluka',
    country,
    state,
    sanghat,
    district,
    taluka,
    value: `${country}/${state}/${sanghat}/${district}/${taluka}`,
  });
  regions.push({ type: 'village', country, state, sanghat, district, taluka, village, code, kendraId: kendra._id, value: `${country}/${state}/${sanghat}/${district}/${taluka}/${village}` });
  return regions;
}

const buildRegions = (kendras) => {
  const regions = [];
  if (!kendras) {
    return regions;
  }

  for (const kendra of kendras) {
    const kendraRegions = regionPerms(kendra);
    for (const kr of kendraRegions) {
      if (!find(regions, (region) => region.value === kr.value)) {
        regions.push(kr);
      }
    }
  }
  return orderBy(regions, (r) => [r.country, r.state, r.sanghat, r.district, r.taluka, r.village]);
};

export const schedulableKendraRegions = createSelector(schedulableKendras, buildRegions);

/**
 * Generates all possible permutations for region, from active Kendras.
 * Returned regions are alphabetically (in it's text representation).
 * Example:
 * Country -> `{type: "country", country: "India", value: "India"}`
 * State -> `{type: "state", country: "India", state: "Gujarat", value: "India/Gujarat"}`
 * Sanghat -> `{type: "sanghat", country: "India", state: "Gujarat", sanghat: "Vadodara", value: "India/Gujarat/Vadodara"}`
 * District -> `{type: "district", country: "India", state: "Gujarat", sanghat: "Vadodara", district: "Surat",  value: ""}`
 * Taluka -> `{type: "taluka", country: "India", state: "Gujarat", sanghat: "Vadodara", "district": "Surat", taluka: "Vyara",  value: ""}`
 * Kendra -> `{type: "kendra", country: "India", state: "Gujarat", sanghat: "Vadodara", "district": "Surat", taluka: "Vyara", village: "Kapoora", code: "235666", value: "kndr_2353535345"}`
 */
export const kendraRegions = createSelector(allKendras, buildRegions);

function tokenize(region) {
  return split(region, '/').map((token) => token.trim());
}

/**
 * Checks whether a kendraRegion is matched by queryRegion.
 * Examples:
 * 1. kendraRegioin="India/Gujarat/Vadodara/Surat/Vyara/Kapoora", queryRegion="India" -> true
 * 2. kendraRegioin="India/Gujarat/Vadodara/Surat/Vyara/Kapoora", queryRegion="India" -> true
 * 3. kendraRegioin="India/Gujarat/Vadodara/Surat/Vyara/Kapoora", queryRegion="India/Gujarat/Vadodara" -> true
 * 4. kendraRegioin="India/Gujarat/Vadodara/Surat/Vyara/Kapoora", queryRegion="India/Gujarat/Vadodara/Bharuch" -> false
 * 5. kendraRegioin="India/Gujarat/Vadodara/Surat/Vyara/Kapoora", queryRegion="India/Goa" -> false
 *
 * @param {String} kendraRegion  A Single criteria (region)
 * @param {String} queryRegion  A single criteria (region)
 */
export function kendraMatchesQuery(kendraRegion, queryRegion) {
  let scheduleTokens = tokenize(kendraRegion);
  let queryTokens = tokenize(queryRegion);
  const min = Math.min(scheduleTokens.length, queryTokens.length);
  scheduleTokens = slice(scheduleTokens, 0, min);
  queryTokens = slice(queryTokens, 0, min);
  return isEqual(scheduleTokens, queryTokens);
}

export function isRegionIncludedIn(child, parent) {
  return kendraMatchesQuery(child, parent);
  // let childTokens = tokenize(child);
  // let parentTokens = tokenize(parent);
  // let childSlicedTokens = slice(childTokens, 0, parentTokens.length);
  // return isEqual(childSlicedTokens, parentTokens);
}

export function kendraRegion(kendra) {
  return `${kendra.country}/${kendra.state}/${kendra.sanghat}/${kendra.district}/${kendra.taluka}/${kendra.village}`;
}

/**
 *
 * @param {Object} kendra
 * @param {Array} criteria
 */
function kendraMatchesAnyCriteria(kendra, criteria) {
  const _kendraRegion = kendraRegion(kendra);
  for (const c of criteria) {
    if (kendraMatchesQuery(_kendraRegion, c)) {
      return true;
    }
  }
  return false;
}

/**
 * @param {Array} criteria
 * @param {Boolean} onlyActive - Status is active and p
 * @param {Boolean} hasPlayer - Kendra has player assigned
 * @param {String} language
 * @returns matching Kendras in sorted order of their region.
 */
export const findByCriteria = (state, criteria, onlyActive = true, hasPlayer = true, language) => {
  let kendras = allKendras(state);
  kendras = kendras.filter((kendra) => kendraMatchesAnyCriteria(kendra, criteria));
  if (onlyActive) {
    kendras = kendras.filter((kendra) => kendra.status == 'ACTIVE');
  }
  if (hasPlayer) {
    kendras = kendras.filter((kendra) => kendra.player);
  }

  if (language) {
    kendras = kendras.filter((kendra) => kendra.language == language);
  }
  return kendras;
};

/**
 * Finds a Kendra by given code.
 * @param {String} code Kendra Code
 * @returns {Object} A matching Kendra.
 */
export const findByCode = memoize(({ state, code }) => {
  const kendras = allKendras(state);
  return kendras.filter((k) => k.code == code)[0];
});

/**
 * It returns current importRequest
 * @param {Object} state
 */
export const importRequest = (state) => state.kendras.importRequest;

/**
 * Returns contry list which are registered with existing kendras, ordered by their names.
 * @param {Object} state
 * @return {Array}
 */
export const countries = memoize((state) => {
  let kendras = allKendras(state);
  if (!kendras) {
    return [];
  }

  let countries = kendras.map((k) => k.country);
  countries = uniq(countries);
  return sortBy(countries);
});

/**
 * Returns states list which are registered with existing kendras
 * @param {Object} state
 * @param {String} country - A name of the country, to which returned states should be limited (filtered).
 * @return {Array} Region[], where `Region` is `{country: "", state: ""}`
 */
export const states = memoize(({ state, country }) => {
  let kendras = allKendras(state);
  if (!kendras) {
    return [];
  }

  kendras = country ? filter(kendras, (k) => k.country === country) : kendras;
  let _states = kendras.map((k) => {
    return { country: k.country, state: k.state };
  });
  _states = uniqBy(_states, (k) => `${k.country}/${k.state}`);
  return sortBy(_states, ['country', 'state']);
});

/**
 * Returns sanghat list which are registered with existing kendras
 * @param {Object} state
 * @param {Object} filters `{country: "", state: ""}` - returned sanghat names are limited to this filters, when specified.
 * @return {Array}
 */
export const sanghats = memoize(({ state, filters }) => {
  let kendras = allKendras(state);
  if (!kendras) {
    return [];
  }

  kendras = filters ? filter(kendras, filters) : kendras;
  let _sanghats = kendras.map((k) => {
    return { country: k.country, state: k.state, sanghat: k.sanghat };
  });
  _sanghats = uniqBy(_sanghats, (k) => `${k.country}/${k.state}/${k.sanghat}`);
  return sortBy(_sanghats, ['country', 'state', 'sanghat']);
});

export const getUniqueDistricts = (kendras) => {
  let _districts = kendras.map((k) => {
    return { country: k.country, state: k.state, sanghat: k.sanghat, district: k.district };
  });
  _districts = uniqBy(_districts, (k) => `${k.country}/${k.state}/${k.sanghat}/${k.district}`);
  return sortBy(_districts, ['country', 'state', 'sanghat', 'district']);
}

/**
 * Returns districts list which are registered with existing kendras
 * @param {Object} state
 * @param {Object} filters `{country: "", state: "", sanghat: ""}` - returned district names are limited to 
 *  this filters, when specified.
 * @return {Array}
 */
export const districts = memoize(({ state, filters }) => {
  let kendras = allKendras(state);
  if (!kendras) {
    return [];
  }

  kendras = filters ? filter(kendras, filters) : kendras;
  return getUniqueDistricts(kendras);
});

/**
 * Returns talukas list which are registered with existing kendras
 * @param {Object} state
 * @param {Object} filters `{country: "", state: "", sanghat: "", district: ""}` - returned taluka names are limited 
 *  to this filters, when specified.
 * @return {Array}
 */
export const talukas = memoize(({ state, filters }) => {
  let kendras = allKendras(state);
  if (!kendras) {
    return [];
  }

  kendras = filters ? filter(kendras, filters) : kendras;
  let _talukas = kendras.map((k) => {
    return { country: k.country, state: k.state, sanghat: k.sanghat, district: k.district, taluka: k.taluka };
  });
  _talukas = uniqBy(_talukas, (k) => `${k.country}/${k.state}/${k.sanghat}/${k.district}/${k.taluka}`);
  return sortBy(_talukas, ['country', 'state', 'sanghat', 'district', 'taluka']);
});

// VIEW KENDRA - START

export const pendingSchedules = memoize(({ state, kendraId }) => {
  const schedules = queryResult(state, `schedules-for-kendra-${kendraId}`);
  const _pendingSchedules = filter(schedules, (s) => !scheduleSelectors.isPastWeek(s.week));
  return _buildPendingSchedules(state, _pendingSchedules, kendraId);
});

export const _buildPendingSchedules = (state, pendingSchedules, kendraId) => {
  let list = [];

  forEach(pendingSchedules, (schedule) => {
    const sps = doc(state, `sps_${schedule._id}_${kendraId}`);

    // Don't add this schedule, if the play time is in the past date.
    const spsDate = moment(sps?.time).startOf('day');
    const currentDate = moment().startOf('day');
    if (spsDate.isBefore(currentDate)) {
      return;
    }

    list.push({ ...schedule, videoDetail: videoSelectors.video(state, schedule.video), time: sps?.time, spsId: sps?._id });
  })

  return list;
}

export const pastSchedules = memoize(({ state, kendraId }) => {
  let spss = queryResult(state, `sps-for-kendra-${kendraId}`);

  if (!spss) {
    return [];
  }

  spss = filter(spss, (sps) => {
    const spsDate = moment(sps?.time).startOf('day');
    const currentDate = moment().startOf('day');
    return spsDate.isBefore (currentDate);
  });

  return spss.map((sps) => {
    return {...sps, videoDetail: videoSelectors.video(state, sps.videoId)};
  });
});


/**
 * It returns array of kendras using the same player as this kendra.
 * Kendras are ordered by their WeekDay & Time.
 * @param {Object} state
 * @param {String} kendraId
 * @return {Array}
 */
export const shrunkhlaKendras = memoize(({ state, kendraId }) => {
  const _kendra = kendra(state, kendraId);
  if (!_kendra.player) {
    return [];
  }

  return playerSelectors.playerKendras({ state, playerId: _kendra.player });
});

export const newKendra = (state) => state.kendras.newKendra;

export const passcode = (state, spsId) => {
  return state.kendras.passcodes[spsId];
}

export const isPasscodeWindowOpened = (state, spsId) => {
  if (!spsId) {
    return;
  }
  const minutes = appSelectors.config(state).passcodeAvailableBeforePlayTime;
  const sps = doc(state, spsId);
  if (!sps) {
    throw new Error(`sps doc not found. id: ${spsId}`);
  }
  const playTime = sps.time; //Timestamp
  if (!playTime) {
    return false;
  }

  const videoDurationSeconds = doc(state, sps.videoId).duration; 
  const currentTime = new Date();
  const playDateTime = new Date(playTime);
  const windowStartTime = new Date(playDateTime.getTime() - minutes * 60000);
  const windowEndTime = new Date(playDateTime.getTime() + videoDurationSeconds * 1000);

  return currentTime >= windowStartTime && currentTime <= windowEndTime;
}

/**
 * Passcode entry for a SPS is locked when `usedPasscodeAttempts` is greater than or equal to `maxPasscodeAttempts`.
 * When `maxPasscodeAttempts` is not set, there is no limit for passcode entry; so, in this case it's never
 * locked out.
 * 
 * @returns `true` if passcode entry is locked for the SPS, `false` otherwise.
 */
export const isPasscodeEntryLocked = (state, spsId) => {
  const sps = doc(state, spsId);
  if (!sps?.maxPasscodeAttempts) {
    return false;
  }

  const usedAttempts = sps.usedPasscodeAttempts || 0;
  if (usedAttempts >= sps.maxPasscodeAttempts) {
    return true;
  }
}

export const rfidRequest = (state) => state?.kendras?.rfidRequest;

// VIEW KENDRA - END