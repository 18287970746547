export const URLs = {
  pages: [
    {
      module: 'players',
      name: 'PLAYERS',
      pathPattern: '/players',
      queryParams: {
        'no-of-kendras': {
          name: 'noOfKendras',
        },
        'online-status': {
          name: 'onlineStatus',
        },
        'player': {
          name: 'playerId',
        }
      },
    },
    {
      module: 'players',
      name: 'PLAYERS',
      pathPattern: '/',
    },
    {
      module: 'videos',
      name: 'VIDEOS',
      pathPattern: '/videos',
      queryParams: {
        'uploaded-from': {
          name: 'uploadedFrom',
        },
        'uploaded-to': {
          name: 'uploadedTo',
        },
        'recorded-from': {
          name: 'recordedFrom',
        },
        'recorded-to': {
          name: 'recordedTo',
        }
      }
    },
    {
      module: 'kendras',
      name: 'KENDRAS',
      pathPattern: '/kendras',
      queryParams: {
        //status, region
        'kendra': {
          name: 'code',
        },
        'mobile': {
          name: 'primaryMobileNo',
        },
        'day': {
          name: 'weekDay',
        },
        'player': {
          name: 'playerCode',
        },
        'id': {
          name: 'kendraId'
        },
        // 'kendras': {
        //   name: 'kendras',
        //   //comma separated array format
        // }
      },
      arrayFormat: 'comma'
    },
    {
      module: 'schedules',
      name: 'SCHEDULES',
      pathPattern: '/schedules/:type',
      queryParams: {
        'week-from': {
          name: 'weekFrom',
        },
        'week-to': {
          name: 'weekTo',
        },
        'kendra-criteria': {
          name: 'kendraCriteria',
        },
        schedules: {
          name: 'schedules',
          array: true
        }
      },
      arrayFormat: 'comma'
    },
    {
      module: 'schedules',
      name: 'SCHEDULE',
      pathPattern: '/schedules/view/:scheduleId',
      queryParams: {
        'player-code': {
          name: 'playerCode'
        }, 
        'kendra-code': {
          name: 'kendraCode'
        },
        'player': {
          name: 'playerId'
        },
        'play-time': {
          name: 'playTime'
        },
        'region': {
          name: 'region',
          type: String,
          array: true
        },
      },
      arrayFormat: 'comma'
    }
  ],

  dialogs: [
    {
      module: 'players',
      name: 'PLAYER',
      pathPattern: '#player/:playerId',
      queryParams: {
        'kendra': {
          name: 'kendraId',
        }
      }
    },
    {
      module: 'videos',
      name: 'VIDEO',
      pathPattern: '#video/:videoId',
    },
    {
      module: 'kendras',
      name: 'KENDRA',
      pathPattern: '#kendra/:kendraId/:tab?',
      dialogParams: {
        kendraId: String,
        tab: String,
      },
    },
    {
      module: 'schedules',
      name: 'DEFAULT_ATTACHMENT_VIDEOS',
      pathPattern: '#default-attachment-videos'
    },
    {
      module: 'schedules',
      name: 'SCHEDULE_CRITERIA',
      pathPattern: '#schedule-criteria',
      dialogParams: {
        id: String,
        sps: String,
      },
    },
  ],
};

export default URLs;
