import { BaseElement, html, css, unsafeCSS } from './components/base-element.js';
import { init as i18nextInit } from './localize/i18next.js';
import './components/app/app-drawer';
import './components/base-element.js';
import { loadPage, loadDialog, loadActionDialog } from './components/module-loader.js';

import './components/app/splash-screen.js';
import './components/app/login-page.js';
import './components/app/permission-denied';
import '@dreamworld/dw-snackbar';

// Redux Imports
import './redux/router';
import * as authSelectors from './redux/auth/selectors.js';
import * as kendraSelectors from './redux/kendras/selectors.js';

// Workbox installer
import installSW from './install-sw.js';

//Theme Imports
import theme from './theme/theme.js';

//Lodash
import get from 'lodash-es/get';

import { DWTooltip, DWTooltipStyle } from "@dreamworld/dw-tooltip";

class AppShell extends BaseElement {
  constructor() {
    super();
    this.doNotDelayRendering = true;
    i18nextInit();
  }

  static properties = {
    /**
     * Current page name. Its computed from the router data.
     */
    _pageName: { type: String },

    /**
     * Current app level dialog name. Its computed from the router data.
     */
    _dialogName: { type: String },

    /**
     * `false` when current user is not admin.
     */
    _admin: { type: Boolean },

    _loggedIn: {type: Boolean},

    /**
     * Current action dialog name. Its computed from the router data.
     */
    _actionDialogName: {
      type: String,
    },

    /**
     * Reprensets whether assign RFID request is in progress, for this Kendra or not.
     */
    _assignRfidInProgress: { type: Boolean },
  };

  static styles = [
    super.styles,
    theme,
    css`
      :host {
        min-height: 100vh;
        display: flex;
        background: var(--mdc-theme-background);
      }

      main {
        min-height: 100vh;
        flex: 1;
        overflow-x: hidden;
      }

      under-maintenance,
      splash-screen,
      permission-denied {
        position: fixed;
        inset: 0;
      }

      .pages > * {
        display: flex;
        flex-direction: column;
        height: 100vh;
      }

      /* Tooltip styles */
      .tippy-box {
        ${unsafeCSS(DWTooltipStyle)};
      }

      .tippy-box[data-placement^='top'] .tippy-arrow {
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        bottom: -10px;
      }

      .tippy-box[data-theme='material'][data-placement^='top'] .tippy-arrow {
        border-top: 10px solid var(--dw-tooltip-background-color);
      }

      .tippy-box[data-placement^='bottom'] .tippy-arrow {
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        top: -8px;
      }

      .tippy-box[data-theme='material'][data-placement^='bottom'] .tippy-arrow {
        border-bottom: 10px solid var(--dw-tooltip-background-color);
      }

      .tippy-box[data-animation='shift-away'][data-state='hidden'] {
        opacity: 0;
      }

      .tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='top'] {
        transform: translateY(10px);
      }

      .tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='bottom'] {
        transform: translateY(-10px);
      }

      .tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='left'] {
        transform: translateX(10px);
      }

      .tippy-box[data-animation='shift-away'][data-state='hidden'][data-placement^='right'] {
        transform: translateX(-10px);
      }

      .tippy-box *[hidden],
      .tippy-box [hidden] {
        display: none !important;
      }
    `,
  ];

  willUpdate(changedProps) {
    super.willUpdate && super.willUpdate(changedProps);
    if (changedProps.has('_pageName') && this._pageName) {
      loadPage(this._pageName);
    }

    if (changedProps.has('_dialogName') && this._dialogName) {
      loadDialog(this._dialogName);
    }

    if (changedProps.has('_actionDialogName') && this._actionDialogName) {
      loadActionDialog(this._actionDialogName);
    }
  }

  firstUpdated(changedProps){
    super.firstUpdated(changedProps);
    installSW();
    let elTippyContainer = this.shadowRoot;
    DWTooltip.setAppendTo(elTippyContainer);
  }

  render() {
    return html`
      ${this._spashScreenTemplate} ${this._underMaintenanceTemplate} ${this._appDrawerTemplate}
      ${this._loginPageTemplate}
      <main>${this._pagesTemplate} ${this._dialogsTemplate} ${this._actionDialogsTemplate}</main>
      <dw-snackbar></dw-snackbar>
    `;
  }

  get _loginPageTemplate() {
    if (this._pageName !== 'LOGIN_PAGE') {
      return;
    }

    return html`<login-page></login-page>`;
  }

  get _spashScreenTemplate() {
    if (this._pageName !== 'SPLASH_SCREEN') {
      return;
    }

    return html`<splash-screen></splash-screen>`;
  }

  get _underMaintenanceTemplate() {
    if (this._pageName !== 'UNDER_MAINTENANCE') {
      return;
    }

    return html`<under-maintenance></under-maintenance>`;
  }

  get _appDrawerTemplate() {
    if (this._pageName === 'LOGIN_PAGE' || this._pageName === 'UNDER_MAINTENANCE' || this._pageName === 'SPLASH_SCREEN' || this._pageName === 'PERMISSION_DENIED') {
      return;
    }
    return html`<app-drawer></app-drawer>`;
  }

  get _notFoundTemplate() {
    if (this._pageName !== 'NOT_FOUND') {
      return;
    }

    return html`<not-found></not-found>`;
  }

  get _playerListTemplate() {
    if (this._pageName !== 'PLAYERS') {
      return;
    }

    return html`<player-list-page></player-list-page>`;
  }

  get _videoPageTemplate() {
    if (this._pageName !== 'VIDEOS') {
      return;
    }
    return html`<video-list-page></video-list-page>`;
  }

  get _kendraPageTemplate() {
    if (this._pageName !== 'KENDRAS') {
      return;
    }

    return html`<kendra-list-page></kendra-list-page>`;
  }

  get _schedulesPageTemplate() {
    if (this._pageName !== 'SCHEDULES') {
      return;
    }
    return html`<schedule-list-page></schedule-list-page>`;
  }

  get _schedulePageTemplate() {
    if (this._pageName !== 'SCHEDULE') {
      return;
    }
    return html`<schedule-view-page></schedule-view-page>`;
  }

  get _permissionDeniedTemplate() {
    if (this._pageName !== 'PERMISSION_DENIED') {
      return;
    }
    return html` <permission-denied></permission-denied> `;
  }

  get _pagesTemplate() {
    if (this._pageName === 'UNDER_MAINTENANCE' || this._pageName === 'SPLASH_SCREEN') {
      return;
    }
    return html`
      <div class="pages">
        ${this._notFoundTemplate} ${this._playerListTemplate} ${this._videoPageTemplate} ${this._kendraPageTemplate}
        ${this._schedulesPageTemplate} ${this._schedulePageTemplate} ${this._permissionDeniedTemplate}
      </div>
    `;
  }

  get _dialogsTemplate() {
    if (this._pageName === 'UNDER_MAINTENANCE' || this._pageName === 'SPLASH_SCREEN') {
      return;
    }

    if (this._dialogName === 'PLAYER') {
      return html`<player-dialog ?opened=${true}></player-dialog>`;
    }

    if (this._dialogName === 'KENDRA') {
      return html`<kendra-view-dialog ?opened=${true}></kendra-view-dialog>`;
    }

    if (this._dialogName === 'VIDEO') {
      return html`<video-view-dialog ?opened=${true}></video-view-dialog>`;
    }

    if (this._dialogName === 'SCHEDULE_CRITERIA') {
      return html`<schedule-validation-criteria-dialog ?opened=${true}></schedule-validation-criteria-dialog>`;
    }

    if (this._dialogName === 'DEFAULT_ATTACHMENT_VIDEOS') {
      return html`<manage-default-attachments-dialog ?opened=${true}></manage-default-attachments-dialog>`;
    }
    return html``;
  }

  get _actionDialogsTemplate() {
    if (this._dialogName === 'PLAYER') {
      if (this._actionDialogName === 'PLAYER_STATUS_CONFIRM') {
        return html`<player-status-confirm-dialog .opened=${true}></player-status-confirm-dialog>`;
      }
      
      if(this._actionDialogName === "PLAYER_STATUS_REMOVE") {
        return html`<player-remove-kendra-confirm-dialog .opened=${true}></player-remove-kendra-confirm-dialog>`
      }

      return html``;
    }

    if (this._assignRfidInProgress) {
      import('./components/kendras/kendra-assign-rfid-dialog.js');
      return html`<kendra-assign-rfid-dialog opened></kendra-assign-rfid-dialog>`;
    }
  }

  /**
   * Sets `_pageName` based on the state.
   */
  __getPageName() {
    if (!this._loggedIn) {
      return 'LOGIN_PAGE';
    }

    if (this._underMaintenance) {
      return 'UNDER_MAINTENANCE';
    }

    if (!this._admin) {
      return 'PERMISSION_DENIED';
    }

    return get(this._page, 'name');
  }

  /**
   * Sets `_dialogName` based on the state.
   */
  __getDialogName() {
    return get(this._dialog, 'name');
  }

  /**
   * Sets `_actionDialogName` based on the state.
   */
  __getActionDialogName() {
    const action = get(this._dialog, 'params.action');
    if (this._dialogName === 'PLAYER') {
      if (action === 'reset' || action === 'archive' || action === 'destroy') {
        return 'PLAYER_STATUS_CONFIRM';
      }
    }

    if (this._dialogName === 'PLAYER') {
      if (action === 'remove-kendra') {
        return 'PLAYER_STATUS_REMOVE';
      }
    }

    return action;
  }

  stateChanged(state) {
    super.stateChanged && super.stateChanged(state);

    this._page = get(state, 'router.page');
    this._dialog = get(state, 'router.dialog');
    this._admin = authSelectors.isAdmin(state);
    this._loggedIn = authSelectors.isLoggedIn(state);
    this._dialogName = this.__getDialogName();
    this._actionDialogName = this.__getActionDialogName();
    this._pageName = this.__getPageName();

    this._assignRfidInProgress = !!kendraSelectors.rfidRequest(state);
  }
}

customElements.define('app-shell', AppShell);
